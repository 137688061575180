// ? hooks
import { useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
// ? stores

import { messageStore } from '@/store/store'

// ? utils
import { inputsChanger } from '@/utils/inputsChanger'

// ? components
import { Button, Box, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { promocodeStore } from '@/store/promocodeStore'
import { IPromocode } from '@/interfaces/promocode'
import { formatDateString } from '@/utils/formatDate'

const EditPromocodeModal = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { addMessage } = messageStore()
  const { editPromocode, getAllPromocodes, transportMeta } = promocodeStore()
  const { isOpenModal, handleCloseModal, editedItem } = editPromocode

  const [formValues, setFormValues] = useState<Partial<IPromocode>>({
    promo_type: editedItem?.promo_type || 'all',
    promo_text: editedItem?.promo_text || '',
    count_limit: editedItem?.count_limit || 0,
    discount: editedItem?.discount || 0,
    expires_at: formatDateString(editedItem?.expires_at!),
    count_activated: editedItem?.count_activated || 0,
  })

  // ? changers
  const inputChangeHandler = inputsChanger('input', formValues, setFormValues)
  const handleChangeSelect = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void
  // ? changers

  // ? api handlers
  const editPromocodeApiHandler = useAsyncWrapper(editPromocode.editPromocodeApiHandler, 'editPromocodeApiHandler')

  const getAllPromocodesApiHandler = useAsyncWrapper(getAllPromocodes, 'getAllPromocodesApiHandler')
  // ? api handlers

  const handleClose = () => {
    setFormValues({})
    handleCloseModal()
  }

  const editCountryHandler = async () => {
    setIsLoading(true)

    const { error: editPromocodeError } = await editPromocodeApiHandler([editedItem?.promo_id, formValues])

    if (editPromocodeError) {
      setIsLoading(false)
      return
    }

    const { error: getPromocodesError } = await getAllPromocodesApiHandler([transportMeta])

    if (getPromocodesError) {
      setIsLoading(false)
      return
    }

    addMessage({
      severity: 'success',
      message: 'Успішно відредаговано',
      isOpen: true,
    })

    setIsLoading(false)
    handleClose()
  }

  if (!editedItem?.promo_id) return null

  return (
    <ModalContainer width={800} isOpen={isOpenModal} handleClose={handleClose} title={'Редагування промокоду'}>
      <FormControl fullWidth variant="standard">
        <InputLabel>Тип промокоду</InputLabel>
        <Select value={formValues.promo_type || ''} name="promo_type" onChange={handleChangeSelect}>
          <MenuItem value={'all'}>Всі</MenuItem>
          <MenuItem value={'travel'}>Подорожі</MenuItem>
          <MenuItem value={'delivery'}>Доставка</MenuItem>
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label="Текст промокоду"
        onChange={inputChangeHandler}
        value={formValues.promo_text || ''}
        name="promo_text"
        variant="standard"
        placeholder="Текст промокоду"
      />
      <TextField
        fullWidth
        label="Ліміт активувань"
        onChange={inputChangeHandler}
        value={formValues.count_limit || 0}
        name="count_limit"
        type="number"
        variant="standard"
        placeholder="Ліміт активувань"
      />
      <TextField
        fullWidth
        label="Знижка"
        onChange={inputChangeHandler}
        value={formValues.discount || 0}
        name="discount"
        type="number"
        variant="standard"
        placeholder="Знижка"
      />
      <TextField
        fullWidth
        label="Дата закінчення"
        onChange={inputChangeHandler}
        value={formValues.expires_at}
        name="expires_at"
        type="date"
        variant="standard"
        placeholder="Дата закінчення"
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton
          loading={isLoading}
          color="primary"
          variant="contained"
          size="small"
          onClick={editCountryHandler}>
          Відредагувати
        </LoadingButton>
        <Button onClick={handleClose} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Відмінити
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default EditPromocodeModal
