import PromocodesTable from '@/components/Tabels/PromocodesTable/PromocodesTable'
import Header from '@/components/UI/GlobalNavigation/Header'
import { Typography } from '@mui/material'

const Promocodes = () => {
  return (
    <>
      <Header />
      {true ? (
        <PromocodesTable />
      ) : (
        <Typography variant="h4" color="error">
          You don&apos;t have permission to view Promocodes
        </Typography>
      )}
    </>
  )
}

export default Promocodes
