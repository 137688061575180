// ? components
import { TableRow, TableCell } from '@mui/material'

const headerTitles = [
  {
    title: 'Тип',
    name: 'promo_type',
  },
  {
    title: 'Текст',
    name: 'promo_text',
  },
  {
    title: 'Знижка',
    name: 'discount',
  },
  {
    title: 'Ліміт',
    name: 'count_limit',
  },
  {
    title: 'Активовано',
    name: 'count_activated',
  },
  {
    title: 'Дата закінчення',
    name: 'expires_at',
  },
  {
    title: 'Дії',
    name: 'actions',
  },
]

const PromocodeTabelHeader = () => {
  return (
    <TableRow>
      {headerTitles?.length
        ? headerTitles.map(({ title, name }) => (
            <TableCell
              key={title}
              sx={{
                backgroundColor: '#222222 !important',
              }}>
              {title}
            </TableCell>
          ))
        : null}
    </TableRow>
  )
}

export default PromocodeTabelHeader
