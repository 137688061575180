// ? hooks
import { useEffect, useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'

// ? components
import { Grid, Button, TableContainer, Table, TableHead, TableBody, Box } from '@mui/material'

import TablePaginationCustom from '@/components/UI/TablePaginationCustom/TablePaginationCustom'
import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'

import EditCountryModal from './modals/EditPromocodeModal'

// ? icons
import { Add } from '@mui/icons-material'
import CreatePromocodeModal from './modals/CreatePromocodeModal'
import { promocodeStore } from '@/store/promocodeStore'
import PromocodeTabelRow from './PromocodeTabelRow'
import PromocodeTabelHeader from './PromocodeTabelHeader'
import DeletePromocodeModal from './modals/DeletePromocodeModal'
import EditPromocodeModal from './modals/EditPromocodeModal'

const PromocodesTable = () => {
  const { getAllPromocodes, promocodes, transportMeta, editPromocode, deletePromocode, isLoading } = promocodeStore()
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  const getAllPromocodesApiHandler = useAsyncWrapper(getAllPromocodes, 'getAllPromocodesApiHandler')

  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true)
  }

  useEffect(() => {
    const fetchData = async () => {
      await getAllPromocodesApiHandler([transportMeta])
    }

    fetchData()
  }, [])

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      {isCreateModalOpen && (
        <CreatePromocodeModal handleClose={() => setIsCreateModalOpen(false)} isOpen={isCreateModalOpen} />
      )}
      {editPromocode.isOpenModal && <EditPromocodeModal />}
      {deletePromocode.isOpenModal && <DeletePromocodeModal />}

      <Grid item xs={12}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createUserBtn"
            endIcon={<Add className="addIcon" />}
            onClick={handleOpenCreateModal}>
            Створити новий промокод
          </Button>
        </Box>
        {promocodes?.length > 0 ? (
          <TableContainer>
            <Table
              stickyHeader
              sx={{
                maxHeight: 'calc(100vh - 250px)',
                width: '100%',
                maxWidth: '100%',
                overflow: 'scroll',
                mt: 1,
              }}>
              <TableHead>
                <PromocodeTabelHeader />
              </TableHead>
              <TableBody>
                {!isLoading ? (
                  promocodes.map((promocode) => <PromocodeTabelRow promocode={promocode} key={promocode.promo_id} />)
                ) : (
                  <LoadingTableData columns={7} rows={5} />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 250px)',
              gap: 2,
            }}>
            <p>Дані відсутні</p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="createUserBtn"
              endIcon={<Add className="addIcon" />}
              onClick={handleOpenCreateModal}>
              Створити новий промокод
            </Button>
          </Box>
        )}
        {promocodes && promocodes?.length !== 0 && (
          <TablePaginationCustom getData={getAllPromocodesApiHandler} meta={transportMeta} />
        )}
      </Grid>
    </Grid>
  )
}
export default PromocodesTable
