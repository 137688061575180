import ModalContainer from '@/components/UI/Modal/ModalContainer'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { promotionStore } from '@/store/promotionStore'
import { messageStore } from '@/store/store'
import { transportStore } from '@/store/transportStore'
import { Delete } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Button, Box } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const DeletePromotionModal = () => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const { deletePromotion, getAllPromotions, transportMeta } = promotionStore()
  const { addMessage } = messageStore()

  const { isOpenModal, handleCloseModal, deletedItem } = deletePromotion

  const deletePromotionApiHandler = useAsyncWrapper(
    deletePromotion.deletePromotionApiHandler,
    'deletePromotionApiHandler'
  )
  const getAllPromotionsApiHandler = useAsyncWrapper(getAllPromotions, 'getAllPromotionsApiHandler')

  const handleCloseForm = () => {
    handleCloseModal()
  }

  const deleteBusHandler = async () => {
    setIsLoading(true)
    if (!deletedItem?.promotion_id) {
      setIsLoading(false)
      return
    }

    const { error: deletePromotionError } = await deletePromotionApiHandler([deletedItem.promotion_id])

    if (deletePromotionError) {
      setIsLoading(false)
      return
    }

    const { error: getAllPromotionsError } = await getAllPromotionsApiHandler([transportMeta])

    if (getAllPromotionsError) {
      setIsLoading(false)
      return
    }

    addMessage({
      severity: 'success',
      message: 'Промоакцію успішно видалено',
      isOpen: true,
    })

    handleCloseForm()
    setIsLoading(false)
  }

  return (
    <ModalContainer isOpen={isOpenModal} handleClose={handleCloseForm} title="Видалення промоакції" width={600}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton
          loading={isLoading}
          color="error"
          variant="contained"
          size="small"
          endIcon={<Delete />}
          onClick={deleteBusHandler}>
          Видалити
        </LoadingButton>
        <Button onClick={handleCloseForm} color="primary" variant="contained" size="small" sx={{ ml: 1 }}>
          {t('transportsPage.cancelCreation')}
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default DeletePromotionModal
