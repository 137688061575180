// ? hooks
import { useEffect, useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import useValidation from '@/hooks/useValidation'

// ? stores
import { countryStore } from '@/store/countryStore'
import { messageStore } from '@/store/store'

// ? interfaces
import { ICountryCreateDTO } from '@/interfaces/country'

// ? validations
import { COUNTRY_VALIDATION } from '@/validation/country'

// ? utils
import { inputsChanger } from '@/utils/inputsChanger'

// ? components
import { Button, Box, TextField, Autocomplete, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { promocodeStore } from '@/store/promocodeStore'
import { IPromocodeCreateDTO } from '@/interfaces/promocode'

interface IProps {
  isOpen: boolean
  handleClose: () => void
}

const initialState: IPromocodeCreateDTO = {
  count_limit: 0,
  discount: 0,
  expires_at: null,
  promo_text: '',
  promo_type: 'all',
}

const CreatePromocodeModal = ({ handleClose, isOpen }: IProps) => {
  const { addMessage } = messageStore()
  const { createPromocode, getAllPromocodes, transportMeta } = promocodeStore()

  const [formValues, setFormValues] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)

  //   const inputChangeTranslateHandler = inputsChanger('inputCountryTranslate', formValues, setFormValues)
  const inputChangeHandler = inputsChanger('input', formValues, setFormValues)
  const handleChangeSelect = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void

  const createCountryValidation = useValidation(COUNTRY_VALIDATION.CREATE)
  const createPromocodeApiHandler = useAsyncWrapper(createPromocode, 'createPromocodeApiHandler')

  //   const getCountryCodesApiHandler = useAsyncWrapper(getCountryCodes, 'getCountryCodesApiHandler')

  const getAllPromocodesApiHandler = useAsyncWrapper(getAllPromocodes, 'getAllPromocodesApiHandler')

  //   const handleSearchSelectChange = (event: any, value: any) => {
  //     if (!value) return
  //     console.log(value, 'value')
  //     setFormValues({
  //       ...formValues,
  //       name: value.label,
  //       countryCode: value.value,
  //     })
  //   }

  const handleCloseForm = () => {
    setFormValues(initialState)
    handleClose()
  }

  const createPromocodeHandler = async () => {
    setIsLoading(true)

    const { error: createPromocodeError } = await createPromocodeApiHandler([formValues])

    if (createPromocodeError) {
      setIsLoading(false)
      return
    }

    const { error: getPromocodesError } = await getAllPromocodesApiHandler([transportMeta])

    if (getPromocodesError) {
      setIsLoading(false)
      return
    }

    addMessage({
      severity: 'success',
      message: 'Успішно створено',
      isOpen: true,
    })

    setIsLoading(false)
    handleClose()
  }

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       const { error: getCountryCodesError } = await getCountryCodesApiHandler([])

  //       if (getCountryCodesError) return
  //     }

  //     fetchData()
  //   }, [])

  return (
    <ModalContainer width={400} isOpen={isOpen} handleClose={handleClose} title={'Створення промокоду'}>
      <FormControl fullWidth variant="standard">
        <InputLabel>Тип промокоду</InputLabel>
        <Select value={formValues.promo_type || ''} name="promo_type" onChange={handleChangeSelect}>
          <MenuItem value={'all'}>Всі</MenuItem>
          <MenuItem value={'travel'}>Подорожі</MenuItem>
          <MenuItem value={'delivery'}>Доставка</MenuItem>
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label="Текст промокоду"
        onChange={inputChangeHandler}
        value={formValues.promo_text || ''}
        name="promo_text"
        variant="standard"
        placeholder="Текст промокоду"
      />
      <TextField
        fullWidth
        label="Ліміт активувань"
        onChange={inputChangeHandler}
        value={formValues.count_limit || ''}
        name="count_limit"
        type="number"
        variant="standard"
        placeholder="Ліміт активувань"
      />
      <TextField
        fullWidth
        label="Знижка"
        onChange={inputChangeHandler}
        value={formValues.discount || ''}
        name="discount"
        type="number"
        variant="standard"
        placeholder="Знижка"
      />
      <TextField
        fullWidth
        label="Дата закінчення"
        onChange={inputChangeHandler}
        value={formValues.expires_at || ''}
        name="expires_at"
        type="date"
        variant="standard"
        placeholder="Дата закінчення"
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton
          loading={isLoading}
          color="primary"
          variant="contained"
          size="small"
          onClick={createPromocodeHandler}>
          Створити
        </LoadingButton>
        <Button onClick={handleClose} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Відмінити
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default CreatePromocodeModal
