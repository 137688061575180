import { DeliveryRequestStatus } from '@/interfaces/deliveryRequests'

export const BoardingStatus = {
  BoardingAndDisembarking: 'Є посадка та висадка',
  BoardingOnly: 'Тільки посадка',
  DisembarkingOnly: 'Тільки висадка',
  NoBoardingAndDisembarking: 'Немає посадки та висадки',
} as const

export const RaceType = {
  Interurban: 'Міжміський',
  Interregional: 'Міжобласний',
  Suburban: 'Приміський',
  International: 'Міжнародний',
} as const

export const RaceRegularity = {
  ByWeekdays: 'По днях тижня',
} as const

export const Weekdays = {
  Mon: 'Пн',
  Tue: 'Вт',
  Wed: 'Ср',
  Thu: 'Чт',
  Fri: 'Пт',
  Sat: 'Сб',
  Sun: 'Нд',
} as const

export const Currency = {
  UAH: 'UAH',
  USD: 'USD',
  EUR: 'EUR',
} as const

export const TariffType = {
  fixed: 'Фіксований',
}

export const ACCESS_TOKEN = 'access_token'
export const REFRESH_TOKEN = 'refresh_token'
export const USER_INFO_TOKEN = 'user_info'

export const timezones = [
  { label: 'Україна GMT+02:00', value: 'GMT+02:00' },
  { label: 'Центральноєвропейський час GMT+01:00', value: 'GMT+01:00' },
]

export const TRIP_REQUEST_STATUS: {
  [key: string]: string
} = {
  created: 'Створено',
  booked: 'Підтверджено',
  canceled: 'Відхилено',
  ongoing: 'В процесі',
  completed: 'Завершено',
} as const

export const CONNECTION_TYPE: {
  [key: string]: string
} = {
  Call: 'Дзвінок',
  viber: 'Viber',
  telegram: 'Telegram',
  whatIsApp: `What's App`,
} as const

export const DELIVERY_STATUS: Record<DeliveryRequestStatus, string> = {
  created: 'Створено',
  canceled: 'Відхилено',
  ongoing: 'В процесі',
  completed: 'Завершено',
} as const

export const DELIVERY_STATUS_TO_UA: Record<string, string> = {
  created: 'Прийнято',
  canceled: 'Відхилено',
  ready_for_pickup: 'Оформлено на забір, очікуйте на дзвінок',
  ongoing: 'В обробці',
  going_to_ukraine: 'Прямує в Україну',
  sent_by_nova_poshta: 'Замовлення відправлено Новою Поштою',
  in_department: ' Замовлення прибуло у відділення',
  completed: 'Завершено',
} as const

export const DELIVERY_STATUS_FROM_UA: Record<string, string> = {
  created: 'Прийнято',
  canceled: 'Відхилено',
  ongoing: 'В обробці',
  going_to_receiver: 'Прямує до отримувача',
  going_to_department: 'Прямує до відділення',
  in_department: 'Замовлення прибуло у відділення',
  completed: 'Завершено',
}

export const PAYMENT_METHOD: {
  [key: string]: string
} = {
  uah_card: 'Карта гривні',
  euro_card: 'Карта євро',
  cash_on_delivery: 'Готівка при отриманні',
  card_on_delivery: 'Картою при отриманні',
  cash_on_arrival: 'Готівкою при отриманні',
  card_on_arrival: 'Картою при отриманні',
  cod: 'Накладний платіж',
}
