import { create } from 'zustand'

import { REQ } from '@/api'
import { IMeta } from '@/interfaces/general'
import { IPromocode, IPromocodeCreateDTO } from '@/interfaces/promocode'

const { promocodes_api } = REQ

interface IStore {
  isLoading: boolean
  promocodes: IPromocode[]
  transportMeta: IMeta
  createPromocode: (body: IPromocodeCreateDTO) => Promise<any>
  getAllPromocodes: () => Promise<any>
  editPromocode: {
    editedItem: IPromocode | null
    handleOpenModal: (item: IPromocode) => void
    handleCloseModal: () => void
    isOpenModal: boolean
    editPromocodeApiHandler: (id: string, body: Partial<IPromocode>) => Promise<any>
  }
  deletePromocode: {
    deletedItem: IPromocode | null
    handleOpenModal: (item: IPromocode) => void
    handleCloseModal: () => void
    isOpenModal: boolean
    deletePromocodeApiHandler: (id: string) => Promise<any>
  }
}

export const promocodeStore = create<IStore>((set) => ({
  isLoading: false,
  promocodes: [],
  transportMeta: {
    page: 1,
    count: 10,
    total: 0,
  },
  editPromocode: {
    editedItem: null,
    handleOpenModal: (item) => {
      set((state) => ({
        ...state,
        editPromocode: {
          ...state.editPromocode,
          editedItem: item,
          isOpenModal: true,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        editPromocode: {
          ...state.editPromocode,
          isOpenModal: false,
        },
      }))
    },
    isOpenModal: false,
    editPromocodeApiHandler: async (id, body) => {
      const response = await promocodes_api.updatePromocode(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  deletePromocode: {
    deletedItem: null,
    handleOpenModal: (item) => {
      set((state) => ({
        ...state,
        deletePromocode: {
          ...state.deletePromocode,
          deletedItem: item,
          isOpenModal: true,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        deletePromocode: {
          ...state.deletePromocode,
          isOpenModal: false,
        },
      }))
    },
    isOpenModal: false,
    deletePromocodeApiHandler: async (id) => {
      const response = await promocodes_api.deletePromocode(id)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },
  createPromocode: async (body) => {
    const response = await promocodes_api.createPromocode(body)

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 201) {
      throw new Error(data)
    } else {
      return response
    }
  },
  getAllPromocodes: async () => {
    set((state) => ({ ...state, isLoading: true }))
    const response = await promocodes_api.getAllPromocodes()

    const { status, data } = response

    if (!status || !data) {
      set((state) => ({ ...state, isLoading: false }))
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      set((state) => ({ ...state, isLoading: false }))

      throw new Error(data)
    } else {
      set((state) => ({
        ...state,
        promocodes: data.promocodes,
      }))
      setTimeout(() => {
        set((state) => ({ ...state, isLoading: false }))
      }, 400)
      return response
    }
  },
}))
