import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { DELIVERY_STATUS, DELIVERY_STATUS_FROM_UA, DELIVERY_STATUS_TO_UA } from '@/constants/constants'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { IDeliveryCountry } from '@/interfaces/country'
import { DeliveryType, IDeliveryRequest } from '@/interfaces/deliveryRequests'
import { IRoute } from '@/interfaces/route'
import { deliveryCountryStore } from '@/store/deliveryCountryStore'
import { deliveryRequestsStore } from '@/store/deliveryRequestsStore'
import { routesStore } from '@/store/routesStore'
import { messageStore } from '@/store/store'
import { validateForm } from '@/utils/formValidation'
import { inputsChanger } from '@/utils/inputsChanger'
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'

const getOriginType = (deliveryRequest: IDeliveryRequest | null) => {
  if (!deliveryRequest) {
    return 'address'
  }

  if (typeof deliveryRequest.origin_department === 'number') {
    return deliveryRequest.origin_department
  }

  if (deliveryRequest.origin_address) {
    return 'address'
  }

  return 'nova_poshta'
}

const getDeliveryType = (deliveryRequest: IDeliveryRequest | null) => {
  if (!deliveryRequest) {
    return 'address'
  }

  if (typeof deliveryRequest.delivery_department === 'number') {
    return deliveryRequest.delivery_department
  }

  if (deliveryRequest.delivery_address) {
    return 'address'
  }

  return 'nova_poshta'
}

type EditDeliveryFormValues = {
  origin_country_id: number | null
  destination_country_id: number | null
  origin_type: 'address' | 'nova_poshta' | number | null
  destination_type: 'address' | 'nova_poshta' | number | null
  isInsurance: number
} & IDeliveryRequest

const formatEditDeliveryBody = (data: EditDeliveryFormValues, selectedRoute: IRoute): IDeliveryRequest => {
  let deliveryType: DeliveryType
  let body: IDeliveryRequest = {
    ...data,
  }

  if (selectedRoute?.origin_name_ua === 'Україна') {
    deliveryType = 'ukraine_to_abroad'
  } else if (selectedRoute?.destination_name_ua === 'Україна') {
    deliveryType = 'abroad_to_ukraine'
  } else {
    deliveryType = 'city_to_city'
  }

  if (data.origin_type === 'address') {
    body.origin_department = null
    body.origin_zip_code = Number(data.origin_zip_code)
  } else if (typeof data.origin_type === 'number') {
    body.origin_address = ''
    body.origin_city = ''

    delete body.origin_zip_code

    body.origin_department = data.origin_type
  } else if (data.origin_type === 'nova_poshta') {
    body.origin_address = ''
    body.origin_city = ''
    delete body.origin_zip_code
    body.origin_department = null
  }

  if (data.destination_type === 'address') {
    body.delivery_department = null
    body.delivery_zip_code = Number(data.delivery_zip_code)
  } else if (typeof data.destination_type === 'number') {
    body.delivery_address = ''
    body.delivery_city = ''
    delete body.delivery_zip_code
    body.delivery_department = data.destination_type
  } else if (data.destination_type === 'nova_poshta') {
    body.delivery_address = ''
    body.delivery_city = ''
    delete body.delivery_zip_code
    body.delivery_department = null
  }

  if (data.discount_type === 'student') {
    body.promocode = 0
  } else if (data.discount_type === 'promocode') {
    body.esic = ''
  }

  if (!body.promocode) {
    delete body.promocode
  } else {
    body.promocode = Number(body.promocode)
  }

  body = {
    ...body,
    delivery_type: deliveryType,
    route_id: data.route_id,
    sender_method: 'courier',
    delivery_method: 'pickup',
  }

  return body
}

export function EditDeliveryRequest({ deliveryType, status }: { status: string; deliveryType: DeliveryType }) {
  const { editModal, getDeliveryRequests } = deliveryRequestsStore()
  const { deliveryRequest, isOpen, handleClose, editDeliveryRequest } = editModal

  const [formValues, setFormValues] = useState<EditDeliveryFormValues>({
    route_id: deliveryRequest?.route_id || null,
    delivery_type: deliveryRequest?.delivery_type || 'ukraine_to_abroad',
    origin_department: deliveryRequest?.origin_department || null,
    origin_city: deliveryRequest?.origin_city || '',
    origin_address: deliveryRequest?.origin_address || '',
    delivery_department: deliveryRequest?.delivery_department || null,
    delivery_city: deliveryRequest?.delivery_city || '',
    delivery_address: deliveryRequest?.delivery_address || '',
    sender_method: deliveryRequest?.sender_method || '',
    delivery_method: deliveryRequest?.delivery_method || '',
    sender_name: deliveryRequest?.sender_name || '',
    sender_phone: deliveryRequest?.sender_phone || '',
    sender_email: deliveryRequest?.sender_email || '',
    receiver_name: deliveryRequest?.receiver_name || '',
    receiver_phone: deliveryRequest?.receiver_phone || '',
    receiver_email: deliveryRequest?.receiver_email || '',
    quantity: deliveryRequest?.quantity || 0,
    weight: deliveryRequest?.weight || 0,
    insurance: deliveryRequest?.insurance || 'none',
    payment_method: deliveryRequest?.payment_method || null,
    promocode: deliveryRequest?.promocode || null,
    origin_country_id: null,
    destination_country_id: null,
    notes: deliveryRequest?.notes || '',
    created_at: deliveryRequest?.created_at || '',
    order_id: deliveryRequest?.order_id || 0,
    payment_status: deliveryRequest?.payment_status || '',
    status: deliveryRequest?.status || 'created',
    delivery_zip_code: deliveryRequest?.delivery_zip_code || null,
    discount: deliveryRequest?.discount || 0,
    insurance_sum: deliveryRequest?.insurance_sum || 0,
    discount_type: deliveryRequest?.discount_type || null,
    origin_zip_code: deliveryRequest?.origin_zip_code || null,
    ttn_number: deliveryRequest?.ttn_number || '',
    destination_type: 'address',
    origin_type: 'address',
    isInsurance: deliveryRequest?.insurance_sum ? 1 : 0,
    price: deliveryRequest?.price || 0,
  })
  const { getAllRoutes, routes } = routesStore()
  const { getAllCountries, countries } = deliveryCountryStore()
  const [selectedRoute, setSelectedRoute] = useState<IRoute | null>(null)

  const { addMessage } = messageStore()

  const [formErrors, setFormErrors] = useState<any>()
  const validationRules = {
    // origin_country_id: {
    //   required: true,
    // },
    // destination_country_id: {
    //   required: true,
    // },
  }

  const getAllRoutesApiHandler = useAsyncWrapper(getAllRoutes, 'getAllRoutesApiHandler')
  const getCountriesApiHandler = useAsyncWrapper(getAllCountries, 'getCountriesApiHandler')
  const getDeliveryRequestsApiHandler = useAsyncWrapper(getDeliveryRequests, 'getDeliveryRequests')

  const selectChangeHandler = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void
  const inputChangeHandler = inputsChanger('input', formValues, setFormValues)

  const handleSubmit = async () => {
    const formErrors = validateForm(formValues, validationRules)
    if (Object.keys(formErrors).length !== 0) {
      setFormErrors(formErrors)

      return
    }

    const data = formatEditDeliveryBody(formValues, selectedRoute!)

    const { error: editDeliveryRequestError } = await editDeliveryRequest(data.order_id, data)

    if (editDeliveryRequestError) {
      return false
    }

    addMessage({
      severity: 'success',
      message: 'Успішно створено',
      isOpen: true,
    })

    const { error: getDeliveryRequestsError } = await getDeliveryRequestsApiHandler([
      {
        delivery_type: deliveryType,
        status,
      },
    ])

    if (getDeliveryRequestsError) {
      return false
    }

    setFormValues({
      route_id: null,
      delivery_type: 'ukraine_to_abroad',
      origin_department: null,
      origin_city: '',
      origin_address: '',
      delivery_department: null,
      delivery_city: '',
      delivery_address: '',
      sender_method: '',
      delivery_method: '',
      sender_name: '',
      sender_phone: '',
      sender_email: '',
      receiver_name: '',
      receiver_phone: '',
      receiver_email: '',
      quantity: 0,
      weight: 0,
      insurance: 'none',
      payment_method: null,
      promocode: null,
      origin_country_id: null,
      destination_country_id: null,
      notes: '',
      created_at: '',
      order_id: 0,
      payment_status: '',
      status: 'created',
      delivery_zip_code: null,
      discount: 0,
      insurance_sum: 0,
      discount_type: null,
      origin_zip_code: null,
      ttn_number: '',
      destination_type: 'address',
      origin_type: 'address',
      isInsurance: 0,
      price: 0,
    })

    handleClose()
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getCountriesError } = await getCountriesApiHandler([])

      if (getCountriesError) {
        return false
      }
    }

    fetchData()

    setFormValues({
      ...formValues,
      ...deliveryRequest,
      origin_type: getOriginType(deliveryRequest),
      destination_type: getDeliveryType(deliveryRequest),
    })

    const selectedRoute = routes?.find((route) => route.route_id === deliveryRequest?.route_id) || null

    setSelectedRoute(selectedRoute)
  }, [deliveryRequest, routes])

  useEffect(() => {
    const fetchData = async () => {
      const { error: getRoutesError } = await getAllRoutesApiHandler([
        formValues.origin_country_id,
        formValues.destination_country_id,
      ])

      if (getRoutesError) {
        return false
      }
    }

    fetchData()
  }, [formValues.origin_country_id, formValues.destination_country_id])

  const deliveryStatus = {
    ukraine_to_abroad: DELIVERY_STATUS_FROM_UA,
    abroad_to_ukraine: DELIVERY_STATUS_TO_UA,
    city_to_city: DELIVERY_STATUS,
  }

  return (
    <ModalContainer
      width={800}
      isOpen={isOpen}
      handleClose={handleClose}
      title="Редагування запиту на доставку"
      height={900}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl error={!!formErrors?.country} fullWidth variant="standard">
            <InputLabel>Країна відправлення</InputLabel>
            <Select
              value={formValues.origin_country_id || ''}
              onChange={selectChangeHandler}
              name="origin_country_id"
              label="Країна">
              {countries?.map((country: IDeliveryCountry) => (
                <MenuItem key={country.country_id} value={country.country_id}>
                  {country.country_name_ua}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.origin_country_id && <FormHelperText>{formErrors?.origin_country_id}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl error={!!formErrors?.destination_country_id} fullWidth variant="standard">
            <InputLabel>Країна доставки</InputLabel>
            <Select
              value={formValues.destination_country_id || ''}
              onChange={selectChangeHandler}
              name="destination_country_id"
              label="Країна">
              {countries?.map((country: IDeliveryCountry) => (
                <MenuItem key={country.country_id} value={country.country_id}>
                  {country.country_name_ua}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.destination_country_id && (
              <FormHelperText>{formErrors?.destination_country_id}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl error={!!formErrors?.route_id} fullWidth variant="standard">
            <InputLabel>Маршрут</InputLabel>
            <Select
              value={formValues.route_id}
              onChange={(e) => {
                selectChangeHandler(e)
                setSelectedRoute(routes?.find((route) => route.route_id === e.target.value) || null)
              }}
              name="route_id">
              {routes?.map((route: IRoute) => (
                <MenuItem key={route.route_id} value={route.route_id}>
                  {`${route.origin_name_ua} -> ${route.destination_name_ua}`}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.route_id && <FormHelperText>{formErrors?.route_id}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider
            sx={{
              my: 2,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl error={!!formErrors?.origin_type} fullWidth variant="standard">
            <InputLabel>Спосіб відправки</InputLabel>
            <Select
              value={formValues.origin_type}
              onChange={selectChangeHandler}
              name="origin_type"
              label="Спосіб відправки">
              {selectedRoute?.origin_name_ua === 'Україна' && (
                <MenuItem key="nova_poshta" value="nova_poshta">
                  Нова Пошта
                </MenuItem>
              )}
              {selectedRoute?.sender_types.includes('address') && (
                <MenuItem key="address" value="address">
                  Адреса
                </MenuItem>
              )}
              {selectedRoute?.sender_departments.map((department) => {
                return (
                  <MenuItem key={department.department_id} value={department.department_id}>
                    відділення {department.city}
                  </MenuItem>
                )
              })}
            </Select>
            {formErrors?.origin_type && <FormHelperText>{formErrors?.origin_type}</FormHelperText>}
          </FormControl>
        </Grid>
        {formValues.origin_type === 'address' && (
          <>
            <Grid item xs={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel shrink>Місто відправлення</InputLabel>
                <TextField
                  name="origin_city"
                  onChange={inputChangeHandler}
                  fullWidth
                  value={formValues.origin_city}
                  variant="standard"
                  sx={{
                    mt: '16px',
                  }}
                  error={!!formErrors?.origin_city}
                  helperText={formErrors?.origin_city}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel shrink>Вулиця відправлення</InputLabel>
                <TextField
                  name="origin_address"
                  onChange={inputChangeHandler}
                  fullWidth
                  value={formValues.origin_address}
                  variant="standard"
                  sx={{
                    mt: '16px',
                  }}
                  error={!!formErrors?.origin_address}
                  helperText={formErrors?.origin_address}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel shrink>Поштовий індекс відправлення</InputLabel>
                <TextField
                  name="origin_zip"
                  onChange={inputChangeHandler}
                  fullWidth
                  value={formValues.origin_zip_code}
                  variant="standard"
                  sx={{
                    mt: '16px',
                  }}
                  error={!!formErrors?.origin_zip}
                  helperText={formErrors?.origin_zip}
                />
              </FormControl>
            </Grid>
          </>
        )}
        {formValues.origin_type === 'nova_poshta' && selectedRoute?.origin_name_ua === 'Україна' ? (
          <Grid item xs={12}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink>Трекінг-номер</InputLabel>
              <TextField
                name="ttn_number"
                onChange={inputChangeHandler}
                fullWidth
                value={formValues.ttn_number}
                variant="standard"
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.ttn_number}
                helperText={formErrors?.ttn_number}
              />
            </FormControl>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Divider
            sx={{
              my: 2,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {
            <FormControl error={!!formErrors?.destination_type} fullWidth variant="standard">
              <InputLabel>Спосіб доставки</InputLabel>
              <Select
                value={formValues.destination_type}
                onChange={selectChangeHandler}
                name="destination_type"
                label="Спосіб доставки">
                {selectedRoute?.destination_name_ua === 'Україна' && (
                  <MenuItem key="nova_poshta" value="nova_poshta">
                    Нова Пошта
                  </MenuItem>
                )}
                {selectedRoute?.delivery_types.includes('address') && (
                  <MenuItem key="address" value="address">
                    Адреса
                  </MenuItem>
                )}
                {selectedRoute?.delivery_departments.map((department) => {
                  return (
                    <MenuItem key={department.department_id} value={department.department_id}>
                      відділення {department.city}
                    </MenuItem>
                  )
                })}
              </Select>
              {formErrors?.destination_type && <FormHelperText>{formErrors?.destination_type}</FormHelperText>}
            </FormControl>
          }
        </Grid>
        {formValues.destination_type === 'address' && (
          <>
            <Grid item xs={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel shrink>Місто доставки</InputLabel>
                <TextField
                  name="delivery_city"
                  onChange={inputChangeHandler}
                  fullWidth
                  value={formValues.delivery_city}
                  variant="standard"
                  sx={{
                    mt: '16px',
                  }}
                  error={!!formErrors?.delivery_city}
                  helperText={formErrors?.delivery_city}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel shrink>Вулиця доставки</InputLabel>
                <TextField
                  name="delivery_address"
                  onChange={inputChangeHandler}
                  fullWidth
                  value={formValues.delivery_address}
                  variant="standard"
                  sx={{
                    mt: '16px',
                  }}
                  error={!!formErrors?.delivery_address}
                  helperText={formErrors?.delivery_address}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel shrink>Поштовий індекс доставки</InputLabel>
                <TextField
                  name="delivery_zip_code"
                  onChange={inputChangeHandler}
                  fullWidth
                  value={formValues.delivery_zip_code}
                  variant="standard"
                  sx={{
                    mt: '16px',
                  }}
                  error={!!formErrors?.delivery_zip_code}
                  helperText={formErrors?.delivery_zip_code}
                />
              </FormControl>
            </Grid>
          </>
        )}
        {formValues.destination_type === 'nova_poshta' && selectedRoute?.destination_name_ua === 'Україна' ? (
          <Grid item xs={12}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink>Трекінг-номер</InputLabel>
              <TextField
                name="ttn_number"
                onChange={inputChangeHandler}
                fullWidth
                value={formValues.ttn_number}
                variant="standard"
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.ttn_number}
                helperText={formErrors?.ttn_number}
              />
            </FormControl>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Divider
            sx={{
              my: 2,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Ім'я отримувача</InputLabel>
            <TextField
              name="receiver_name"
              onChange={inputChangeHandler}
              fullWidth
              value={formValues.receiver_name}
              variant="standard"
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.receiver_name}
              helperText={formErrors?.receiver_name}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Телефон отримувача</InputLabel>
            <TextField
              name="receiver_phone"
              onChange={inputChangeHandler}
              fullWidth
              value={formValues.receiver_phone}
              variant="standard"
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.receiver_phone}
              helperText={formErrors?.receiver_phone}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Пошта отримувача</InputLabel>
            <TextField
              name="receiver_email"
              onChange={inputChangeHandler}
              fullWidth
              value={formValues.receiver_email}
              variant="standard"
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.receiver_email}
              helperText={formErrors?.receiver_email}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider
            sx={{
              my: 2,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Кількість</InputLabel>
            <TextField
              name="quantity"
              onChange={inputChangeHandler}
              fullWidth
              value={formValues.quantity}
              variant="standard"
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.quantity}
              helperText={formErrors?.quantity}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Вага</InputLabel>
            <TextField
              name="weight"
              onChange={inputChangeHandler}
              fullWidth
              value={formValues.weight}
              variant="standard"
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.weight}
              helperText={formErrors?.weight}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider
            sx={{
              my: 2,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl error={!!formErrors?.isInsurance} fullWidth variant="standard">
            <InputLabel shrink>Страхування</InputLabel>
            <Select
              value={formValues.isInsurance}
              onChange={selectChangeHandler}
              name="isInsurance"
              label="Страхування">
              <MenuItem value={0}>Ні</MenuItem>
              <MenuItem value={1}>Так</MenuItem>
            </Select>
            {formErrors?.isInsurance && <FormHelperText>{formErrors?.isInsurance}</FormHelperText>}
          </FormControl>
        </Grid>
        {formValues.isInsurance ? (
          <Grid item xs={4}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink>Сума страхування</InputLabel>
              <TextField
                name="insurance_sum"
                onChange={inputChangeHandler}
                fullWidth
                type="number"
                value={formValues.insurance_sum}
                variant="standard"
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.insurance_sum}
                helperText={formErrors?.insurance_sum}
              />
            </FormControl>
          </Grid>
        ) : null}
        <Grid item xs={4}>
          <FormControl error={!!formErrors?.payment_method} fullWidth variant="standard">
            <InputLabel shrink>Спосіб оплати</InputLabel>
            <Select
              value={formValues.payment_method}
              onChange={selectChangeHandler}
              name="payment_method"
              label="Спосіб оплати">
              <MenuItem value="uah_card">Карта гривні</MenuItem>
              <MenuItem value="eur_card">Карта євро</MenuItem>
              <MenuItem value="cash_on_delivery">Готівка при отриманні</MenuItem>
              <MenuItem value="card_on_delivery">Картою при отриманні</MenuItem>
            </Select>
            {formErrors?.payment_method && <FormHelperText>{formErrors?.payment_method}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider
            sx={{
              my: 2,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl error={!!formErrors?.discount_type} fullWidth variant="standard">
            <InputLabel shrink>Тип знижки</InputLabel>
            <Select
              value={formValues.discount_type}
              onChange={selectChangeHandler}
              name="discount_type"
              label="Тип знижки">
              <MenuItem value="student">Студент</MenuItem>
              <MenuItem value="promocode">Промокод</MenuItem>
              <MenuItem value="">Без знижки</MenuItem>
            </Select>
            {formErrors?.discount_type && <FormHelperText>{formErrors?.discount_type}</FormHelperText>}
          </FormControl>
        </Grid>
        {formValues.discount_type === 'promocode' && (
          <Grid item xs={6}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink>Промокод</InputLabel>
              <TextField
                name="promocode"
                onChange={inputChangeHandler}
                fullWidth
                value={formValues.promocode}
                variant="standard"
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.promocode}
                helperText={formErrors?.promocode}
              />
            </FormControl>
          </Grid>
        )}
        {formValues.discount_type === 'student' && (
          <Grid item xs={6}>
            <FormControl fullWidth variant="standard">
              <InputLabel shrink>ESIC</InputLabel>
              <TextField
                name="esic"
                onChange={inputChangeHandler}
                fullWidth
                value={formValues.esic}
                variant="standard"
                sx={{
                  mt: '16px',
                }}
                error={!!formErrors?.esic}
                helperText={formErrors?.esic}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Примітки</InputLabel>
            <TextField
              name="notes"
              onChange={inputChangeHandler}
              fullWidth
              value={formValues.notes}
              variant="standard"
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.notes}
              helperText={formErrors?.notes}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth variant="standard">
            <InputLabel shrink>Ціна</InputLabel>
            <TextField
              name="price"
              onChange={inputChangeHandler}
              fullWidth
              value={formValues.price}
              variant="standard"
              sx={{
                mt: '16px',
              }}
              error={!!formErrors?.price}
              helperText={formErrors?.price}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl error={!!formErrors?.status} fullWidth variant="standard">
            <InputLabel>Статус</InputLabel>
            <Select value={formValues.status} onChange={selectChangeHandler} name="status">
              {Object.entries(deliveryStatus[formValues.delivery_type]).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.origin_country_id && <FormHelperText>{formErrors?.origin_country_id}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>
      <Button
        sx={{
          mt: 2,
          width: '100%',
        }}
        onClick={handleSubmit}
        variant="contained"
        color="primary">
        Редагувати Замовлення
      </Button>
    </ModalContainer>
  )
}
