import { api } from '@/api'
import ModalContainer from '@/components/UI/Modal/ModalContainer'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { IPromotionCreateDTO } from '@/interfaces/promotion'
import { promotionStore } from '@/store/promotionStore'
import { messageStore } from '@/store/store'
import { inputsChanger } from '@/utils/inputsChanger'
import { LoadingButton } from '@mui/lab'
import { Box, FormControl, InputLabel, Select, MenuItem, TextField, Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const EditPromotionModal = () => {
  const { t } = useTranslation()

  const { editPromotion, getAllPromotions, transportMeta } = promotionStore()
  const { editedItem, handleCloseModal, isOpenModal } = editPromotion
  const [file, setFile] = useState<File | null>(null)

  const { addMessage } = messageStore()

  const [formValues, setFormValues] = useState<IPromotionCreateDTO>({
    promotion_photo: editedItem?.promotion_photo,
    accessibility: editedItem?.accessibility ? 1 : 0,
    promotion_text: editedItem?.promotion_text || '',
    promotion_type: editedItem?.promotion_type || 'all',
  })
  const [loading, setLoading] = useState(false)

  // ? API Handlers
  const getAllPromotionsApiHandler = useAsyncWrapper(getAllPromotions, 'getAllPromotionsApiHandler')
  const editPromotionApiHandler = useAsyncWrapper(editPromotion.editPromotionApiHandler, 'editPromotionApiHandler')
  // ? API Handlers

  // ? Handlers
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, files } = e.target as HTMLInputElement
    if (type === 'file' && files) {
      setFile(files[0])
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        [name]: type === 'number' ? Number(value) : value,
      }))
    }
  }

  const handleChangeSelect = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void

  const handleCloseForm = () => {
    setFormValues({} as IPromotionCreateDTO)
    handleCloseModal()
  }

  const editBusHandler = async () => {
    setLoading(true)

    if (!editedItem?.promotion_id) return

    const formData = new FormData()
    formData.append('promotion_text', formValues.promotion_text)
    formData.append('promotion_type', formValues.promotion_type)
    formData.append('accessibility', formValues.accessibility ? '1' : '0')

    console.log('file', file)

    if (file) {
      formData.append('promotion_photo', file)
    }

    try {
      const { status } = await api.put(`/admin/services/promotions/${editedItem.promotion_id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (status !== 200) {
        throw new Error('Failed to update promotion')
      }

      const { error: getAllPromotionsError } = await getAllPromotionsApiHandler([transportMeta])

      if (getAllPromotionsError) {
        throw new Error(getAllPromotionsError.message)
      }

      addMessage({
        severity: 'success',
        message: 'Промоакцію успішно відредаговано',
        isOpen: true,
      })
      handleCloseForm()
    } catch (error) {
      console.error('Error:', error)
      addMessage({
        severity: 'error',
        message: 'Failed to update transport',
        isOpen: true,
      })
    } finally {
      setLoading(false)
    }
  }

  return editedItem?.promotion_id ? (
    <ModalContainer isOpen={isOpenModal} handleClose={handleCloseForm} title="Редагування промоакції" width={600}>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '16px',
        }}>
        <FormControl fullWidth variant="standard">
          <InputLabel>Тип акції</InputLabel>
          <Select value={formValues.promotion_type || ''} name="promotion_type" onChange={handleChangeSelect}>
            <MenuItem value={'all'}>Всі</MenuItem>
            <MenuItem value={'travel'}>Подорожі</MenuItem>
            <MenuItem value={'delivery'}>Доставка</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Текст акції"
          onChange={handleChangeInput}
          value={formValues.promotion_text || ''}
          name="promotion_text"
          variant="standard"
          placeholder="Текст акції"
        />
        <FormControl fullWidth variant="standard">
          <TextField
            type="file"
            id="promotion_photo"
            name="promotion_photo"
            onChange={handleChangeInput}
            style={{ marginTop: '8px' }}
          />
        </FormControl>
        <FormControl fullWidth variant="standard">
          <InputLabel>Активна</InputLabel>
          <Select value={formValues.accessibility || ''} name="accessibility" onChange={handleChangeSelect}>
            <MenuItem value={1}>Так</MenuItem>
            <MenuItem value={0}>Ні</MenuItem>
          </Select>
        </FormControl>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 1,
          }}>
          <LoadingButton loading={loading} color="primary" variant="contained" size="small" onClick={editBusHandler}>
            {t('transportsPage.editBus')}
          </LoadingButton>

          <Button onClick={handleCloseForm} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
            {t('transportsPage.cancelCreation')}
          </Button>
        </Box>
      </Box>
    </ModalContainer>
  ) : null
}

export default EditPromotionModal
