import LoadingTableData from '@/components/UI/LoadingTableData/LoadingTableData'
import { Grid, Button, TableContainer, Table, TableHead, TableBody } from '@mui/material'
import { Box } from '@mui/system'
import { Add } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import PromotionTabelHeader from './PromotionTabelHeader'
import { useTranslation } from 'react-i18next'
import CreatePromotionModal from './modals/CreatePromotionModal'
import { messageStore } from '@/store/store'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import TablePaginationCustom from '@/components/UI/TablePaginationCustom/TablePaginationCustom'
import { promotionStore } from '@/store/promotionStore'
import PromotionsTabelRow from './PromotionsTabelRow'
import { IPromotion } from '@/interfaces/promotion'
import PromotionPhotoModal from './modals/PromotionPhotoModal'
import DeletePromotionModal from './modals/DeletePromotionModal'
import EditPromotionModal from './modals/EditPromotionModal'

const PromotionsTable = () => {
  const { t } = useTranslation()

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  const { getAllPromotions, promotions, transportMeta, editPromotion, deletePromotion, photosPromotion, isLoading } =
    promotionStore()
  const { addMessage } = messageStore()

  const getAllPromotionsApiHandler = useAsyncWrapper(getAllPromotions, 'getAllPromotionsApiHandler')

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false)
  }

  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true)
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getAllPromotionsError } = await getAllPromotionsApiHandler([transportMeta])

      if (getAllPromotionsError) {
        addMessage({
          severity: 'error',
          message: t('errors.wentWrong'),
          isOpen: true,
        })
      }
    }

    fetchData()
  }, [])

  return (
    <Grid container spacing={1} sx={{ pr: 1 }}>
      {isCreateModalOpen && <CreatePromotionModal isOpen={isCreateModalOpen} handleClose={handleCloseCreateModal} />}

      {editPromotion.isOpenModal && <EditPromotionModal />}

      {deletePromotion.isOpenModal && <DeletePromotionModal />}

      {photosPromotion.isOpenModal && <PromotionPhotoModal />}

      <Grid item xs={12}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="createUserBtn"
            endIcon={<Add className="addIcon" />}
            onClick={handleOpenCreateModal}>
            Додати промоакцію
          </Button>
        </Box>
        {promotions?.length > 0 ? (
          <TableContainer>
            <Table
              stickyHeader
              sx={{
                maxHeight: 'calc(100vh - 250px)',
                width: '100%',
                maxWidth: '100%',
                overflow: 'scroll',
                mt: 1,
              }}>
              <TableHead>
                <PromotionTabelHeader />
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <LoadingTableData rows={5} columns={5} />
                ) : (
                  promotions.map((promotion: IPromotion) => (
                    <PromotionsTabelRow promotion={promotion} key={promotion.promotion_id} />
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 250px)',
              gap: 2,
            }}>
            <p>Дані відсутні</p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="createUserBtn"
              endIcon={<Add className="addIcon" />}
              onClick={handleOpenCreateModal}>
              Додати промоакції
            </Button>
          </Box>
        )}
        {promotions && promotions.length !== 0 && (
          <TablePaginationCustom getData={getAllPromotionsApiHandler} meta={transportMeta} />
        )}
      </Grid>
    </Grid>
  )
}

export default PromotionsTable
