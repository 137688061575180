import { AppBar, Box, Typography, useTheme } from '@mui/material'
import { NavLink, useNavigate } from 'react-router-dom'

import { Group, Logout } from '@mui/icons-material'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import PercentIcon from '@mui/icons-material/Percent'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'

import { useTranslation } from 'react-i18next'

import { barStyle, buttonStyle, boxLinkStyle, boxLinkStyleGlobal } from './styles'

import { adminStore } from '@/store/adminStore'
import { messageStore } from '@/store/store'
import { USER_INFO_TOKEN } from '@/constants/constants'
import { Link } from 'react-router-dom'

const Header = () => {
  const { t } = useTranslation()

  const i18nTranslateNavigationString = 'global.adminNavigation'

  const admin = JSON.parse(localStorage.getItem(USER_INFO_TOKEN) ?? '{}')

  const navigate = useNavigate()

  const theme = useTheme()

  const { logout } = adminStore()
  const { addMessage } = messageStore()

  const logoutHandler = () => {
    logout()

    addMessage({
      isOpen: true,
      message: 'Admin has successfully logged out',
      severity: 'success',
    })
    navigate('/auth')
  }

  return (
    <AppBar position="sticky" sx={barStyle}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '100%',
          width: '100%',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}>
        <Box
          sx={{
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'black',
            padding: '0 10px',
            borderRadius: '5px',
            justifyContent: 'center',
            mr: 1,
            mb: {
              xs: 1,
              md: 0,
            },
            width: {
              xs: '100%',
              md: 'auto',
            },
          }}>
          <Link to="/">
            <Typography
              variant="h6"
              sx={{ ml: 1, display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'white' }}>
              {process.env.REACT_APP_DEV_BASE_BRANDNAME || 'NO Brand Name'}
            </Typography>
          </Link>
        </Box>
        <Box sx={boxLinkStyle}>
          <NavLink
            to={'/admins'}
            style={({ isActive }) => ({
              ...buttonStyle,
              ...(isActive
                ? {
                    backgroundColor: 'black',
                    color: theme.palette.primary.main,
                    padding: '8px 10px',
                    width: '100%',
                    borderBottom: `2px solid ${theme.palette.primary.main}`,
                  }
                : null),
            })}>
            <SupervisorAccountIcon color="primary" />
            <Typography variant="body2" sx={{ ml: 1 }}>
              Адміни
            </Typography>
          </NavLink>
        </Box>
        <Box sx={boxLinkStyle}>
          <NavLink
            to={'/users'}
            style={({ isActive }) => ({
              ...buttonStyle,
              ...(isActive
                ? {
                    backgroundColor: 'black',
                    color: theme.palette.primary.main,
                    padding: '8px 10px',
                    width: '100%',
                    borderBottom: `2px solid ${theme.palette.primary.main}`,
                  }
                : null),
            })}>
            <Group color="primary" />
            <Typography variant="body2" sx={{ ml: 1 }}>
              {t(`${i18nTranslateNavigationString}.users`)}
            </Typography>
          </NavLink>
        </Box>
        <Box sx={boxLinkStyle}>
          <NavLink
            to={'/promotions'}
            style={({ isActive }) => ({
              ...buttonStyle,
              ...(isActive
                ? {
                    backgroundColor: 'black',
                    color: theme.palette.primary.main,
                    padding: '8px 10px',
                    width: '100%',
                    borderBottom: `2px solid ${theme.palette.primary.main}`,
                  }
                : null),
            })}>
            <PercentIcon color="primary" />
            <Typography variant="body2" sx={{ ml: 1 }}>
              Промоакції
            </Typography>
          </NavLink>
        </Box>
        <Box sx={boxLinkStyle}>
          <NavLink
            to={'/promocodes'}
            style={({ isActive }) => ({
              ...buttonStyle,
              ...(isActive
                ? {
                    backgroundColor: 'black',
                    color: theme.palette.primary.main,
                    padding: '8px 10px',
                    width: '100%',
                    borderBottom: `2px solid ${theme.palette.primary.main}`,
                  }
                : null),
            })}>
            <MonetizationOnIcon color="primary" />
            <Typography variant="body2" sx={{ ml: 1 }}>
              Промокоди
            </Typography>
          </NavLink>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'flex-end',
          mt: {
            xs: 1,
            md: 0,
          },
          flexWrap: 'wrap',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mr: 2,
          }}>
          <Typography variant="body2" sx={{ mr: 1, fontSize: 12 }}>
            {/* Admin: {currentAdmin?.email} */}
            Admin: {admin.pib}
          </Typography>
        </Box>
        <Box onClick={() => logoutHandler()} sx={buttonStyle}>
          <Logout color="primary" />
          <Typography variant="body2" sx={{ ml: 1 }}>
            {t(`${i18nTranslateNavigationString}.logout`)}
          </Typography>
        </Box>
      </Box>
    </AppBar>
  )
}

export default Header
