import ModalContainer from '@/components/UI/Modal/ModalContainer'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import { IDeliveryCountry } from '@/interfaces/country'
import { CreateDeliveryDTO, DeliveryType } from '@/interfaces/deliveryRequests'
import { IRoute } from '@/interfaces/route'
import { deliveryCountryStore } from '@/store/deliveryCountryStore'
import { deliveryRequestsStore } from '@/store/deliveryRequestsStore'
import { routesStore } from '@/store/routesStore'
import { messageStore } from '@/store/store'
import { validateForm } from '@/utils/formValidation'
import { inputsChanger } from '@/utils/inputsChanger'
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { DeliveryRequestToAbroad } from './DeliveryRequestToAbroad'
import { DeliveryRequestFromAbroad } from './DeliveryRequestFromAbroad'
import { DeliveryRequestCityToCity } from './DeliveryRequestCityToCity'

export type CreateDeliveryFormValues = {
  origin_country_id: number | null
  destination_country_id: number | null
  origin_type: 'address' | 'nova_poshta' | 'nova_poshta_department' | number | null
  destination_type: 'address' | 'nova_poshta' | 'nova_poshta_department' | number | null
  isInsurance: boolean
} & CreateDeliveryDTO

const handleDeliveryType = (selectedRoute: IRoute | null): DeliveryType => {
  if (!selectedRoute) {
    return 'city_to_city'
  }

  if (selectedRoute?.origin_name_ua === 'Україна') {
    return 'ukraine_to_abroad'
  }

  if (selectedRoute?.destination_name_ua === 'Україна') {
    return 'abroad_to_ukraine'
  }

  return 'city_to_city'
}

const formatCreateDeliveryBody = (data: CreateDeliveryFormValues, selectedRoute: IRoute): CreateDeliveryDTO => {
  let body: CreateDeliveryDTO = {
    ...data,
  }

  if (data.origin_type === 'address') {
    body.origin_department = null
    body.origin_zip_code = Number(data.origin_zip_code)
  } else if (typeof data.origin_type === 'number') {
    body.origin_address = ''
    body.origin_city = ''
    delete body.origin_zip_code
    body.origin_department = data.origin_type
  } else if (data.origin_type === 'nova_poshta') {
    body.origin_address = ''
    body.origin_city = ''
    delete body.origin_zip_code
    body.origin_department = null
  }

  if (data.destination_type === 'address') {
    body.delivery_department = null
    body.delivery_zip_code = Number(data.delivery_zip_code)
  } else if (typeof data.destination_type === 'number') {
    body.delivery_address = ''
    body.delivery_city = ''
    delete body.delivery_zip_code
    body.delivery_department = data.destination_type
  } else if (data.destination_type === 'nova_poshta') {
    body.delivery_address = ''
    body.delivery_city = ''
    delete body.delivery_zip_code
    body.delivery_department = null
  } else if (data.destination_type === 'nova_poshta_department') {
    body.delivery_address = `np_${data.delivery_address}`
    body.delivery_department = null
  }

  if (data.discount_type === 'student') {
    body.promocode = 0
  } else if (data.discount_type === 'promocode') {
    body.esic = ''
  } else {
    body.promocode = null
    body.esic = ''
  }

  if (!body.promocode) {
    delete body.promocode
  } else {
    body.promocode = Number(body.promocode)
  }

  body = {
    ...body,
    delivery_type: handleDeliveryType(selectedRoute),
    route_id: data.route_id,
    sender_method: 'courier',
    delivery_method: 'pickup',
    sender_phone: '123',
  }

  return body
}

export function CreateDeliveryRequest({ status, deliveryType }: { status: string; deliveryType: DeliveryType }) {
  const [formValues, setFormValues] = useState<CreateDeliveryFormValues>({
    route_id: null,
    delivery_type: 'ukraine_to_abroad',
    origin_department: null,
    origin_city: '',
    origin_address: '',
    delivery_department: null,
    delivery_city: '',
    delivery_address: '',
    sender_method: '',
    delivery_method: '',
    sender_name: '',
    sender_phone: '123',
    sender_email: '',
    receiver_name: '',
    receiver_phone: '',
    receiver_email: '',
    quantity: 0,
    weight: 0,
    insurance: 'none',
    payment_method: null,
    promocode: null,
    origin_country_id: null,
    destination_country_id: null,
    notes: '',
    delivery_zip_code: null,
    origin_zip_code: null,
    discount_type: null,
    discount: 0,
    insurance_sum: 0,
    ttn_number: '',
    destination_type: null,
    origin_type: null,
    isInsurance: false,
    esic: '',
  })

  const { createModal, getDeliveryRequests } = deliveryRequestsStore()
  const { getAllRoutes, routes } = routesStore()
  const { getAllCountries, countries } = deliveryCountryStore()
  const [selectedRoute, setSelectedRoute] = useState<IRoute | null>(null)

  const { isOpen, handleClose, createDeliveryRequest } = createModal
  const { addMessage } = messageStore()

  const [formErrors, setFormErrors] = useState<any>()
  const validationRules = {
    // origin_country_id: {
    //   required: true,
    // },
    // destination_country_id: {
    //   required: true,
    // },
  }

  const getAllRoutesApiHandler = useAsyncWrapper(getAllRoutes, 'getAllRoutesApiHandler')
  const getCountriesApiHandler = useAsyncWrapper(getAllCountries, 'getCountriesApiHandler')
  const getDeliveryRequestsApiHandler = useAsyncWrapper(getDeliveryRequests, 'getDeliveryRequests')

  const selectChangeHandler = inputsChanger('selectChanger', formValues, setFormValues) as (e: any) => void
  const inputChangeHandler = inputsChanger('input', formValues, setFormValues)

  const handleSubmit = async () => {
    const formErrors = validateForm(formValues, validationRules)
    if (Object.keys(formErrors).length !== 0) {
      setFormErrors(formErrors)

      return
    }

    const data = formatCreateDeliveryBody(formValues, selectedRoute!)

    try {
      const { error: createDeliveryError } = await createDeliveryRequest(data)

      if (createDeliveryError) {
        addMessage({
          severity: 'error',
          message: 'Помилка при створенні',
          isOpen: true,
        })
        return
      }

      const { error: getDeliveryRequestsError } = await getDeliveryRequestsApiHandler([
        {
          delivery_type: deliveryType,
          status,
        },
      ])

      if (getDeliveryRequestsError) {
        addMessage({
          severity: 'error',
          message: 'Помилка при отриманні',
          isOpen: true,
        })
        return
      }

      addMessage({
        severity: 'success',
        message: 'Успішно створено',
        isOpen: true,
      })

      setFormValues({
        route_id: null,
        delivery_type: 'ukraine_to_abroad',
        origin_department: null,
        origin_city: '',
        origin_address: '',
        delivery_department: null,
        delivery_city: '',
        delivery_address: '',
        sender_method: '',
        delivery_method: '',
        sender_name: '',
        sender_phone: '',
        sender_email: '',
        receiver_name: '',
        receiver_phone: '',
        receiver_email: '',
        quantity: 0,
        weight: 0,
        insurance: 'none',
        payment_method: null,
        promocode: null,
        origin_country_id: null,
        destination_country_id: null,
        notes: '',
        delivery_zip_code: null,
        origin_zip_code: null,
        discount_type: null,
        discount: 0,
        insurance_sum: 0,
        ttn_number: '',
        destination_type: null,
        origin_type: null,
        isInsurance: false,
        esic: '',
      })

      handleClose()
    } catch (error) {
      console.log('error', error)
      addMessage({
        severity: 'error',
        // @ts-ignore
        message: error.response.data.message,
        isOpen: true,
      })
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const { error: getCountriesError } = await getCountriesApiHandler([])

      if (getCountriesError) {
        return false
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const { error: getRoutesError } = await getAllRoutesApiHandler([
        formValues.origin_country_id,
        formValues.destination_country_id,
      ])

      if (getRoutesError) {
        return false
      }
    }

    fetchData()
  }, [formValues.origin_country_id, formValues.destination_country_id])

  const renderFormComponent = () => {
    switch (formValues.delivery_type) {
      case 'ukraine_to_abroad':
        return (
          <DeliveryRequestToAbroad
            formErrors={formErrors}
            formValues={formValues}
            inputChangeHandler={inputChangeHandler}
            selectChangeHandler={selectChangeHandler}
            selectedRoute={selectedRoute}
          />
        )
      case 'abroad_to_ukraine':
        return (
          <DeliveryRequestFromAbroad
            formErrors={formErrors}
            formValues={formValues}
            inputChangeHandler={inputChangeHandler}
            selectChangeHandler={selectChangeHandler}
            selectedRoute={selectedRoute}
          />
        )
      case 'city_to_city':
        return (
          <DeliveryRequestCityToCity
            formErrors={formErrors}
            formValues={formValues}
            inputChangeHandler={inputChangeHandler}
            selectChangeHandler={selectChangeHandler}
            selectedRoute={selectedRoute}
          />
        )
      default:
        return null
    }
  }

  const handleRouteChange = (e: any) => {
    selectChangeHandler(e)

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      origin_department: null,
      origin_city: '',
      origin_address: '',
      delivery_department: null,
      delivery_city: '',
      delivery_address: '',
      sender_method: '',
      delivery_method: '',
      sender_name: '',
      sender_phone: '',
      sender_email: '',
      receiver_name: '',
      receiver_phone: '',
      receiver_email: '',
      quantity: 0,
      weight: 0,
      insurance: 'none',
      payment_method: null,
      promocode: null,
      origin_country_id: null,
      destination_country_id: null,
      notes: '',
      delivery_zip_code: null,
      origin_zip_code: null,
      discount_type: null,
      discount: 0,
      insurance_sum: 0,
      ttn_number: '',
      destination_type: null,
      origin_type: null,
      isInsurance: false,
      esic: '',
    }))
  }

  useEffect(() => {
    if (formValues.route_id) {
      const selectedRoute = routes?.find((route) => route.route_id === formValues.route_id) || null
      setSelectedRoute(selectedRoute)

      setFormValues((prev) => ({
        ...prev,
        delivery_type: handleDeliveryType(selectedRoute),
      }))
    }
  }, [formValues.route_id, routes])

  return (
    <ModalContainer
      width={800}
      isOpen={isOpen}
      handleClose={handleClose}
      title="Створення нового запиту на доставку"
      height={900}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl error={!!formErrors?.country} fullWidth variant="standard">
            <InputLabel>Країна відправлення</InputLabel>
            <Select
              value={formValues.origin_country_id || ''}
              onChange={selectChangeHandler}
              name="origin_country_id"
              label="Країна">
              {countries?.map((country: IDeliveryCountry) => (
                <MenuItem key={country.country_id} value={country.country_id}>
                  {country.country_name_ua}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.origin_country_id && <FormHelperText>{formErrors?.origin_country_id}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl error={!!formErrors?.destination_country_id} fullWidth variant="standard">
            <InputLabel>Країна доставки</InputLabel>
            <Select
              value={formValues.destination_country_id || ''}
              onChange={selectChangeHandler}
              name="destination_country_id"
              label="Країна">
              {countries?.map((country: IDeliveryCountry) => (
                <MenuItem key={country.country_id} value={country.country_id}>
                  {country.country_name_ua}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.destination_country_id && (
              <FormHelperText>{formErrors?.destination_country_id}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl error={!!formErrors?.route_id} fullWidth variant="standard">
            <InputLabel>Маршрут</InputLabel>
            <Select value={formValues.route_id} onChange={handleRouteChange} name="route_id" label="Маршрут">
              {routes?.map((route: IRoute) => (
                <MenuItem key={route.route_id} value={route.route_id}>
                  {`${route.origin_name_ua} -> ${route.destination_name_ua}`}
                </MenuItem>
              ))}
            </Select>
            {formErrors?.route_id && <FormHelperText>{formErrors?.route_id}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider
            sx={{
              my: 2,
            }}
          />
        </Grid>
        {renderFormComponent()}
      </Grid>
      <Button
        sx={{
          mt: 2,
          width: '100%',
        }}
        onClick={handleSubmit}
        variant="contained"
        color="primary">
        Створити Замовлення
      </Button>
    </ModalContainer>
  )
}
