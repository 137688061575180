import { api } from '@/api'
import { IPromotionCreateDTO } from '@/interfaces/promotion'
import { ITransport, ITransportCreateDTO } from '@/interfaces/transport'

import { PREFIXES } from '@/routes/prefixes'
import { apiCreator } from '@/utils/apiCreator'

const requestCreator = apiCreator(PREFIXES.PROMOTIONS)

export const promotion_api = {
  getAllPromotions: async () => requestCreator(api, '', 'GET'),

  createPromotion: async (body: IPromotionCreateDTO) =>
    requestCreator(api, '', 'POST', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),

  updatePromotion: async (id: string, body: Partial<ITransport>) => requestCreator(api, `${id}`, 'PUT', body),

  deletePromotion: async (id: string) => requestCreator(api, `${id}`, 'DELETE'),

  getById: async (id: string) => requestCreator(api, `${id}`, 'GET'),

  getPromotionPhoto: async (id: number) => {
    return api.get(`/admin/services/promotions/${id}/photo`)
  },
}
