import { api } from '@/api'
import { IPromocodeCreateDTO, IPromocode } from '@/interfaces/promocode'

import { PREFIXES } from '@/routes/prefixes'
import { apiCreator } from '@/utils/apiCreator'

const requestCreator = apiCreator(PREFIXES.PROMOCODES)

export const promocodes_api = {
  getAllPromocodes: async () => requestCreator(api, '', 'GET'),

  createPromocode: async (body: IPromocodeCreateDTO) => requestCreator(api, '', 'POST', body),

  updatePromocode: async (id: string, body: Partial<IPromocode>) => requestCreator(api, `${id}`, 'PUT', body),

  deletePromocode: async (id: string) => requestCreator(api, `${id}`, 'DELETE'),

  getById: async (id: string) => requestCreator(api, `${id}`, 'GET'),
}
