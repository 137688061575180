import PromotionsTable from '@/components/Tabels/PromotionsTable/PromotionsTable'
import Header from '@/components/UI/GlobalNavigation/Header'
import { Typography } from '@mui/material'

const Promotions = () => {
  return (
    <>
      <Header />
      {true ? (
        <PromotionsTable />
      ) : (
        <Typography variant="h4" color="error">
          You don&apos;t have permission to view traders
        </Typography>
      )}
    </>
  )
}

export default Promotions
