import { create } from 'zustand'

import { REQ } from '@/api'
import { IMeta } from '@/interfaces/general'
import { IPromotion, IPromotionCreateDTO } from '@/interfaces/promotion'

const { promotion_api } = REQ

interface IStore {
  isLoading: boolean
  promotions: IPromotion[]
  transportMeta: IMeta
  createPromotion: (body: IPromotionCreateDTO) => Promise<any>
  getAllPromotions: () => Promise<any>
  editPromotion: {
    editedItem: IPromotion | null
    handleOpenModal: (item: IPromotion) => void
    handleCloseModal: () => void
    isOpenModal: boolean
    editPromotionApiHandler: (id: string, body: Partial<IPromotion>) => Promise<any>
  }
  deletePromotion: {
    deletedItem: IPromotion | null
    handleOpenModal: (item: IPromotion) => void
    handleCloseModal: () => void
    isOpenModal: boolean
    deletePromotionApiHandler: (id: string) => Promise<any>
  }
  photosPromotion: {
    promotionItem: IPromotion | null
    handleOpenModal: (item: IPromotion) => void
    handleCloseModal: () => void
    isOpenModal: boolean
  }
}

export const promotionStore = create<IStore>((set) => ({
  isLoading: false,
  promotions: [],
  transportMeta: {
    page: 1,
    count: 10,
    total: 0,
  },
  editPromotion: {
    editedItem: null,
    handleOpenModal: (item) => {
      set((state) => ({
        ...state,
        editPromotion: {
          ...state.editPromotion,
          editedItem: item,
          isOpenModal: true,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        editPromotion: {
          ...state.editPromotion,
          isOpenModal: false,
        },
      }))
    },
    isOpenModal: false,
    editPromotionApiHandler: async (id, body) => {
      const response = await promotion_api.updatePromotion(id, body)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },

  deletePromotion: {
    deletedItem: null,
    handleOpenModal: (item) => {
      set((state) => ({
        ...state,
        deletePromotion: {
          ...state.deletePromotion,
          deletedItem: item,
          isOpenModal: true,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        deletePromotion: {
          ...state.deletePromotion,
          isOpenModal: false,
        },
      }))
    },
    isOpenModal: false,
    deletePromotionApiHandler: async (id) => {
      const response = await promotion_api.deletePromotion(id)

      const { status, data } = response

      if (!status || !data) {
        throw new Error('No status code or data returned from server.')
      }

      if (status !== 200) {
        throw new Error(data)
      } else {
        return response
      }
    },
  },
  photosPromotion: {
    promotionItem: null,
    handleOpenModal: (item) => {
      set((state) => ({
        ...state,
        photosPromotion: {
          ...state.photosPromotion,
          promotionItem: item,
          isOpenModal: true,
        },
      }))
    },
    handleCloseModal: () => {
      set((state) => ({
        ...state,
        photosPromotion: {
          ...state.photosPromotion,
          isOpenModal: false,
          transportItem: null,
        },
      }))
    },
    isOpenModal: false,
  },

  createPromotion: async (body) => {
    const response = await promotion_api.createPromotion(body)

    const { status, data } = response

    if (!status || !data) {
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 201) {
      throw new Error(data)
    } else {
      return response
    }
  },
  getAllPromotions: async () => {
    set((state) => ({ ...state, isLoading: true }))
    const response = await promotion_api.getAllPromotions()

    const { status, data } = response

    if (!status || !data) {
      set((state) => ({ ...state, isLoading: false }))
      throw new Error('No status code or data returned from server.')
    }

    if (status !== 200) {
      set((state) => ({ ...state, isLoading: false }))

      throw new Error(data)
    } else {
      set((state) => ({
        ...state,
        promotions: data.promotions,
      }))
      setTimeout(() => {
        set((state) => ({ ...state, isLoading: false }))
      }, 400)
      return response
    }
  },
}))
