// ? utils
import { formatDate2 } from '@/utils/formatDate'

// ? components
import { TableRow, TableCell, Typography, Tooltip, IconButton } from '@mui/material'

// ? icons
import { Delete, Edit } from '@mui/icons-material'
import { IPromocode } from '@/interfaces/promocode'
import { promocodeStore } from '@/store/promocodeStore'

const PROMOCODES_TYPES = {
  all: 'Всі',
  delivery: 'Доставка',
  travel: 'Подорожі',
} as any

interface IProps {
  promocode: IPromocode
}

const PromocodeTabelRow = ({ promocode }: IProps) => {
  const { count_activated, discount, count_limit, promo_text, promo_type, expires_at } = promocode

  const { editPromocode, deletePromocode } = promocodeStore()

  const handleEditCountry = (promocode: IPromocode) => {
    editPromocode.handleOpenModal(promocode)
  }

  const handleDeleteCountry = (promocode: IPromocode) => {
    deletePromocode.handleOpenModal(promocode)
  }

  return (
    <TableRow hover>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {PROMOCODES_TYPES[promo_type]}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {promo_text}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {discount}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {count_limit}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          sx={{
            cursor: 'pointer',
          }}>
          {count_activated}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            cursor: 'pointer',
          }}>
          {formatDate2(expires_at as string)}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Tooltip title="Відредагувати">
          <IconButton color="warning" onClick={() => handleEditCountry(promocode)}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Видалити">
          <IconButton color="error" onClick={() => handleDeleteCountry(promocode)}>
            <Delete />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default PromocodeTabelRow
