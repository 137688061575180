// ? hooks
import { useState } from 'react'
import useAsyncWrapper from '@/hooks/useAsyncWrapper'
import useValidation from '@/hooks/useValidation'

// ? stores
import { countryStore } from '@/store/countryStore'
import { messageStore } from '@/store/store'

// ? validations
import { COUNTRY_VALIDATION } from '@/validation/country'

// ? components
import { Button, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import ModalContainer from '@/components/UI/Modal/ModalContainer'
import { promocodeStore } from '@/store/promocodeStore'

const DeletePromocodeModal = () => {
  const { addMessage } = messageStore()
  const { deletePromocode, getAllPromocodes, transportMeta } = promocodeStore()
  const { isOpenModal, handleCloseModal, deletedItem } = deletePromocode

  const [isLoading, setIsLoading] = useState(false)

  const deletePromocodeApiHandler = useAsyncWrapper(
    deletePromocode.deletePromocodeApiHandler,
    'deletePromocodeApiHandler'
  )

  const getAllPromocodesApiHandler = useAsyncWrapper(getAllPromocodes, 'getAllPromocodesApiHandler')

  const deleteValidation = useValidation(COUNTRY_VALIDATION.DELETE)

  const deleteCountryHandler = async () => {
    setIsLoading(true)
    const id = deletedItem?.promo_id

    if (!deleteValidation(id)) {
      setIsLoading(false)
      return false
    }

    const { error: deletePromocodeError } = await deletePromocodeApiHandler([id])

    if (deletePromocodeError) {
      setIsLoading(false)
      return false
    }

    const { error: getPromocodesError } = await getAllPromocodesApiHandler([transportMeta])

    if (getPromocodesError) {
      setIsLoading(false)
      return false
    }

    addMessage({
      severity: 'success',
      message: 'Успішно видалено',
      isOpen: true,
    })
    setIsLoading(false)

    handleCloseModal()
  }

  return (
    <ModalContainer width={800} isOpen={isOpenModal} handleClose={handleCloseModal} title={'Видалення промокоду'}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}>
        <LoadingButton
          loading={isLoading}
          color="primary"
          variant="contained"
          size="small"
          onClick={deleteCountryHandler}>
          Видалити
        </LoadingButton>
        <Button onClick={handleCloseModal} color="error" variant="contained" size="small" sx={{ ml: 1 }}>
          Відмінити
        </Button>
      </Box>
    </ModalContainer>
  )
}

export default DeletePromocodeModal
